import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';

import InputWrapper from './style';

function Input(props) {
  const {
    onChange,
    className,
    type,
    value,
    name,
    defaultValue,
    onBlur,
    disabled,
    placeholder,
    width,
    height,
    marginTop,
    marginBottom,
    overflow,
    multiline,
    helperText,
    error,
    required
  } = props;
  return (
    <InputWrapper>
      <TextField
        name={name}
        size="small"
        margin="normal"
        required={required}
        type={type}
        helperText={helperText}
        error={error}
        value={value}
        variant="outlined"
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        onBlur={onBlur}
        disabled={disabled}
        multiline={multiline}
        sx={{
          width,
          height,
          overflow,
          marginTop,
          marginBottom
        }}
      />
    </InputWrapper>
  );
}
Input.propTypes = {
  className: PropTypes.string
};
export default Input;
