import React, { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Toast = ({
  message = '',
  severity = 'success',
  toastOpen = '',
  handleToastClose = () => { }
}) => {
  const Alert = forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

  return (
    <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={toastOpen} autoHideDuration={4000} onClose={handleToastClose}>
      <Alert onClose={handleToastClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Toast;
