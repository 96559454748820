import React, {
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import momentTz from 'moment-timezone';
import {
  Box,
  Grid,
  Stack
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

import Button from '../../components/button';
import SimpleTable from '../../components/table';
import Toast from '../../components/toast';

import { DownloadErrorLogs, GetErrorLogs, SetRepricerState } from '../../reducers/repricer';

import { pageChangeRange } from '../../constants';

const ErrorLogs = () => {
  const dispatch = useDispatch();
  const {
    errorLogsData,
    total,
    err,
    loading,
    message,
    success
  } = useSelector((state) => state.repricer);
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const columns = [
    {
      id: 'shortName',
      label: 'Short Name',
      minWidth: 200,
      align: 'left'
    },
    {
      id: 'asin',
      label: 'Asin',
      minWidth: 250,
      align: 'left',
      sortingId: 'keyword'
    },
    {
      id: 'errorMessage',
      label: 'Error Message',
      minWidth: 250,
      align: 'left',
      sortingId: 'message'
    },
    {
      id: 'errorCreatedAt',
      label: 'Error Created At',
      minWidth: 100,
      align: 'left',
      sortingId: 'errorCreatedAt'
    }
  ];

  function createData(
    shortName,
    asin,
    errorMessage,
    errorCreatedAt
  ) {
    return {
      shortName,
      asin,
      errorMessage,
      errorCreatedAt
    };
  }

  const pageChange = (page, limit) => {
    setPageLimit(limit);
    setPageNumber(page);
  };

  const createKeywordsData = () => {
    const rowList = [];
    for (let i = 0; i < errorLogsData.length; i += 1) {
      const {
        shortName,
        asin,
        message: errorMessage,
        errorCreatedAt
      } = errorLogsData[i];
      rowList.push(createData(
        shortName || 'N/A',
        asin || 'N/A',
        errorMessage || 'N/A',
        errorCreatedAt ? momentTz(errorCreatedAt).tz('America/Los_Angeles').format('lll') : 'N/A'
      ));
    }
    return rowList;
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleRefreshButton = () => {
    const setObj = {
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit
    };
    dispatch(GetErrorLogs({ ...setObj }));
  };

  useEffect(() => {
    if (errorLogsData?.length) {
      const result = createKeywordsData();
      setRowData(result);
    } else {
      setRowData([]);
    }
  }, [errorLogsData, loading]);

  useEffect(() => {
    const setObj = {
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit
    };
    dispatch(GetErrorLogs({ ...setObj }));
  }, [pageNumber, pageLimit]);

  useEffect(() => {
    if (err) {
      setMessage(err);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetRepricerState({ field: 'err', value: '' }));
    }
  }, [err]);

  useEffect(() => {
    if (success && !err && message) {
      setMessage(message);
      setSeverity('success');
      setToastOpen(true);
      dispatch(SetRepricerState({ field: 'success', value: false }));
    }
  }, [success, err]);

  return (
    <>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }} mr={1}>
        <h2>Error Logs</h2>
        <Stack
          direction="row"
          justifyContent="right"
          alignItems="center"
          flexWrap="wrap"
          columnGap={2}
        >
          <Button
            text="Error Logs"
            onClick={() => dispatch(DownloadErrorLogs())}
            varient="outlined"
            icon={<DownloadIcon />}
          />
        </Stack>
      </Box>
      <Grid conatiner="true">
        <Grid item md={12}>
          <SimpleTable
            rows={rowData}
            loading={loading}
            totalRows={total}
            columns={columns}
            className="error-log-table-height"
            selectLabel="page"
            onChange={pageChange}
            pageLimit={pageLimit}
            pageNumber={pageNumber}
            data={pageChangeRange}
            onRefresh={handleRefreshButton}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ErrorLogs;
