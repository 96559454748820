import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router-dom';

import { setAuthToken } from '../config/axios-configuration';

import { handleMakeRouteArray } from '../constants';

const PrivateRoute = ({
  component: Component,
  withLayout: Layout,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();
  const { token, user } = useSelector((state) => state.auth);
  const { role, permissions } = user;
  setAuthToken(token);

  useEffect(() => {
    if (!token || token === '') {
      history.push('/auth/sign-in');
    } else if (token) {
      const notNormalUserPages = handleMakeRouteArray(permissions);
      if (role === 'normal-user' && !notNormalUserPages.includes(location.pathname)) {
        if (notNormalUserPages.includes('/rules')) {
          history.push('/rules');
        } else if (notNormalUserPages.includes('/repricer-logs')) {
          history.push('/repricer-logs');
        } else {
          history.push('/error-logs');
        }
      } else {
        history.push(location.pathname);
      }
    }
  }, [token]);

  const renderRoute = () => (
    <Route {...rest} render={(props) => <Component {...props} />} />
  );
  return <Layout>{renderRoute()}</Layout>;
};

export default PrivateRoute;
