import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosBaseUrl } from '../config/axios-configuration';

const axios = axiosBaseUrl();

const initialState = {
  rulesData: [],
  total: 0,
  loading: false,
  err: null,
  success: false
};

export const DeleteRule = createAsyncThunk('/delete-rule', async (data, thunkAPI) => {
  try {
    const {
      documentId
    } = data;
    const response = await axios.delete('/rules/delete-rule', {
      params: {
        documentId
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return thunkAPI.rejectWithValue({
        err: error.response.data,
        status: error.response.status
      });
    }
    return thunkAPI.rejectWithValue({
      err: {
        error: 'Network Error'
      }
    });
  }
});

export const GetRules = createAsyncThunk('/get-rules', async (data, thunkAPI) => {
  try {
    const {
      searchKeyword,
      limit,
      skip
    } = data;
    const response = await axios.get('/rules/get-rules', {
      params: {
        searchKeyword,
        limit,
        skip
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return thunkAPI.rejectWithValue({
        err: error.response.data,
        status: error.response.status
      });
    }
    return thunkAPI.rejectWithValue({
      err: {
        error: 'Network Error'
      }
    });
  }
});

export const SaveRule = createAsyncThunk('/save-rule', async (data, thunkAPI) => {
  try {
    const response = await axios.post('/rules/save-rule', data);

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return thunkAPI.rejectWithValue({
        err: error.response.data,
        status: error.response.status
      });
    }
    return thunkAPI.rejectWithValue({
      err: {
        error: 'Network Error'
      }
    });
  }
});

const rules = createSlice({
  name: 'rulesReducer',
  initialState,
  reducers: {
    SetRulesState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [DeleteRule.pending]: (state) => ({
      ...state,
      loading: true,
      err: null,
      success: false
    }),
    [DeleteRule.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: null,
      message: action.payload.message,
      success: action.payload.success
    }),
    [DeleteRule.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    }),
    [GetRules.pending]: (state) => ({
      ...state,
      loading: true,
      err: null,
      success: false
    }),
    [GetRules.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: null,
      rulesData: action.payload.rulesData,
      total: action.payload.total,
      message: action.payload.message
    }),
    [GetRules.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    }),
    [SaveRule.pending]: (state) => ({
      ...state,
      loading: true,
      err: null,
      success: false
    }),
    [SaveRule.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: null,
      message: action.payload.message,
      success: action.payload.success
    }),
    [SaveRule.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    })
  }
});

export const { SetRulesState } = rules.actions;
export default rules.reducer;
