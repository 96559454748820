import Styled from 'styled-components';

const AdminWrapper = Styled.div`
    .rortate-icon{
        background-color: ${({ theme }) => theme['primary-color']};
        color: ${({ theme }) => theme['white-color']};
        padding:5px 15px!important;
        &:hover{
                  background-color: ${({ theme }) => theme['primary-color']};
        }
      .MuiButton-startIcon{
            transform: rotate(180deg);
      }
    }
    .add-border-last{
      max-height:550px
    }
`;

export default AdminWrapper;
