import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Divider
} from '@mui/material';

import BasicModal from '../../components/modal';
import Button from '../../components/button';

import { DeleteRule } from '../../reducers/rules';

const closeStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0px 4px 8px rgba(54, 50, 235, 0.23)',
  width: '65%',
  bgcolor: 'background.paper'
};

const AddOrEditRuleModal = (props) => {
  const dispatch = useDispatch();
  const {
    deleteRuleModal,
    setDeleteRuleModal,
    documentId
  } = props;

  const deleteRule = () => {
    dispatch(DeleteRule({ documentId }));
    setDeleteRuleModal(false);
  };

  return (
    <BasicModal modalToggle={deleteRuleModal}>
      <Box sx={closeStyle}>
        <Box display="flex" flexDirection="column" justifyContent="center" p={2} alignItems="center">
          <h1>Are you sure?</h1>
          <p className="text-center">
            Do you really want to delete this rule? This
            action will not be undone.
          </p>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="center" columnGap={3} p={2} alignItems="center">
          <Button text="cancel" onClick={() => setDeleteRuleModal(false)} varient="outlined" className="outlined" />
          <Button text="Delete" varient="contained" className="modal-contained" onClick={() => deleteRule()} />
        </Box>
      </Box>
    </BasicModal>
  );
};

export default AddOrEditRuleModal;
