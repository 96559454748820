import React from 'react';
import {
  Box,
  Divider,
  Stack,
  Typography
} from '@mui/material';

import BasicModal from '../../components/modal';
import Button from '../../components/button';
import Input from '../../components/input';
import { isValidAsin } from '../../constants';

const closeStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0px 4px 8px rgba(54, 50, 235, 0.23)',
  width: '65%',
  bgcolor: 'background.paper'
};

const AddOrEditRuleModal = (props) => {
  const {
    addRule,
    editRule,
    ruleValues,
    setRuleValues,
    buttonDisable,
    setButtonDisable,
    errorOnTitleInput,
    setErrorOnTitleInput,
    errorOnAsinInput,
    setErrorOnAsinInput,
    errorOnCompetitorAsinInput,
    setErrorOnCompetitorAsinInput,
    errorOnPriceInput,
    setErrorOnPriceInput,
    errorOnLowestPriceInput,
    setErrorOnLowestPriceInput,
    errorOnTimeInput,
    setErrorOnTimeInput,
    handleCloseAddEditRuleModal,
    handleSaveRules
  } = props;

  return (
    <BasicModal modalToggle={addRule || editRule}>
      <Box sx={closeStyle}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          columnGap={12}
        >
          <Box>
            <h4>{addRule ? 'Add Rule' : 'Edit Rule'}</h4>
          </Box>
        </Box>
        <Divider />
        <Box pl={2} pr={2}>
          <Box>
            <Stack
              pr={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              columnGap={2}
            >
              <Typography>
                Short Name
              </Typography>
              <Input
                helperText={errorOnTitleInput.helperText}
                error={errorOnTitleInput.error}
                defaultValue={ruleValues.shortName}
                onChange={(event) => {
                  if (event.target.value !== '') {
                    setErrorOnTitleInput({ helperText: '', error: false });
                    setButtonDisable(false);
                    setRuleValues({
                      ...ruleValues,
                      shortName: event.target.value
                    });
                  } else {
                    setButtonDisable(true);
                    setErrorOnTitleInput({
                      helperText: 'Field Value Can\'t Be Empty',
                      error: true
                    });
                  }
                }}
                required
              />
            </Stack>
            <Stack
              pr={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              columnGap={2}
            >
              <Typography>
                Asin
              </Typography>
              <Input
                helperText={errorOnAsinInput.helperText}
                error={errorOnAsinInput.error}
                defaultValue={ruleValues.asin}
                onChange={(event) => {
                  if (event.target.value !== '') {
                    if (isValidAsin(event.target.value)) {
                      setErrorOnAsinInput({ helperText: '', error: false });
                      setButtonDisable(false);
                      setRuleValues({
                        ...ruleValues,
                        asin: event.target.value
                      });
                    } else {
                      setButtonDisable(true);
                      setErrorOnAsinInput({
                        helperText: 'Please Enter A Valid Asin',
                        error: true
                      });
                    }
                  } else {
                    setButtonDisable(true);
                    setErrorOnAsinInput({
                      helperText: 'Field Value Can\'t Be Empty',
                      error: true
                    });
                  }
                }}
                required
              />
            </Stack>
            <Stack
              pr={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              columnGap={2}
            >
              <Typography>
                Competitor Asin
              </Typography>
              <Input
                helperText={errorOnCompetitorAsinInput.helperText}
                error={errorOnCompetitorAsinInput.error}
                defaultValue={ruleValues.competitorAsin}
                onChange={(event) => {
                  if (event.target.value !== '') {
                    if (isValidAsin(event.target.value)) {
                      if (!(event.target.value === ruleValues.asin)) {
                        setErrorOnCompetitorAsinInput({ helperText: '', error: false });
                        setButtonDisable(false);
                        setRuleValues({
                          ...ruleValues,
                          competitorAsin: event.target.value
                        });
                      } else {
                        setButtonDisable(true);
                        setErrorOnCompetitorAsinInput({
                          helperText: 'Competitor Asin And Our Asin Can\'t Be Same',
                          error: true
                        });
                      }
                    } else {
                      setButtonDisable(true);
                      setErrorOnCompetitorAsinInput({
                        helperText: 'Please Enter A Valid Asin',
                        error: true
                      });
                    }
                  } else {
                    setButtonDisable(true);
                    setErrorOnCompetitorAsinInput({
                      helperText: 'Field Value Can\'t Be Empty',
                      error: true
                    });
                  }
                }}
                required
              />
            </Stack>
            <Stack
              pr={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              columnGap={2}
            >
              <Typography>
                Price Discount
              </Typography>
              <Input
                type="Number"
                helperText={errorOnPriceInput.helperText}
                error={errorOnPriceInput.error}
                defaultValue={ruleValues.priceDiscount}
                onChange={(event) => {
                  if (event.target.value !== '') {
                    if (event.target.value > 0) {
                      setErrorOnPriceInput({ helperText: '', error: false });
                      setButtonDisable(false);
                      setRuleValues({
                        ...ruleValues,
                        priceDiscount: Number(event.target.value)
                      });
                    } else {
                      setButtonDisable(true);
                      setErrorOnPriceInput({
                        helperText: event.target.value !== '0'
                          ? 'Price Value Can\'t Be Negative'
                          : 'Price Value Can\'t Be Zero',
                        error: true
                      });
                    }
                  } else {
                    setButtonDisable(true);
                    setErrorOnPriceInput({
                      helperText: 'Price Value Can\'t Be Empty',
                      error: true
                    });
                  }
                }}
                required
              />
            </Stack>
            <Stack
              pr={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              columnGap={2}
            >
              <Typography>
                Lowest Price
              </Typography>
              <Input
                type="Number"
                helperText={errorOnLowestPriceInput.helperText}
                error={errorOnLowestPriceInput.error}
                defaultValue={ruleValues.lowestPrice}
                onChange={(event) => {
                  if (event.target.value !== '') {
                    if (event.target.value > 0) {
                      setErrorOnLowestPriceInput({ helperText: '', error: false });
                      setButtonDisable(false);
                      setRuleValues({
                        ...ruleValues,
                        lowestPrice: Number(event.target.value)
                      });
                    } else {
                      setButtonDisable(true);
                      setErrorOnLowestPriceInput({
                        helperText: event.target.value !== '0'
                          ? 'Price Value Can\'t Be Negative'
                          : 'Price Value Can\'t Be Zero',
                        error: true
                      });
                    }
                  } else {
                    setButtonDisable(true);
                    setErrorOnLowestPriceInput({
                      helperText: 'Price Value Can\'t Be Empty',
                      error: true
                    });
                  }
                }}
                required
              />
            </Stack>
            <Stack
              pr={2}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              columnGap={2}
            >
              <Typography>
                Check Every X Hours
              </Typography>
              <Input
                type="Number"
                helperText={errorOnTimeInput.helperText}
                error={errorOnTimeInput.error}
                defaultValue={ruleValues.checkAfter}
                onChange={(event) => {
                  if (event.target.value !== '') {
                    if (event.target.value > 0) {
                      setErrorOnTimeInput({ helperText: '', error: false });
                      setButtonDisable(false);
                      setRuleValues({
                        ...ruleValues,
                        checkAfter: Number(event.target.value)
                      });
                    } else {
                      setButtonDisable(true);
                      setErrorOnTimeInput({
                        helperText: event.target.value !== '0'
                          ? 'Time Value Can\'t Be Negative'
                          : 'Time Value Can\'t Be Zero',
                        error: true
                      });
                    }
                  } else {
                    setButtonDisable(true);
                    setErrorOnTimeInput({
                      helperText: 'Time Value Can\'t Be Empty',
                      error: true
                    });
                  }
                }}
                required
              />
            </Stack>
          </Box>
          <Box display="flex" justifyContent="center" columnGap={3} p={2} alignItems="center">
            <Button text="cancel" onClick={() => handleCloseAddEditRuleModal()} varient="outlined" className="outlined" />
            <Button
              text="Save"
              varient="contained"
              disabled={buttonDisable}
              className="modal-contained"
              onClick={() => handleSaveRules()}
            />
          </Box>
        </Box>
      </Box>
    </BasicModal>
  );
};

export default AddOrEditRuleModal;
