import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Tooltip } from '@mui/material';

import Loading from '../loading';
import ButtonWrapper from './style';

const Buttons = (props) => {
  const {
    text, icon, varient, onClick, className, disabled, type = 'button', loading, isTooltip
  } = props;

  return (
    <Stack direction="row" spacing={2}>
      {
        isTooltip
          ? (
            <Tooltip title="Refresh" placement="top">
              <ButtonWrapper>
                <Button size="small" type={type} className={className} variant={varient} startIcon={icon} onClick={onClick} disabled={disabled}>
                  {text}
                </Button>
              </ButtonWrapper>
            </Tooltip>
          )
          : (
            <ButtonWrapper>
              <Button size="small" type={type} className={className} variant={varient} startIcon={icon} onClick={onClick} disabled={disabled}>
                {text}
              </Button>
              {loading ? <Loading paddingTop="0%" /> : ''}
            </ButtonWrapper>
          )
      }
    </Stack>
  );
};

Buttons.propTypes = {
  text: PropTypes.string,
  varient: PropTypes.string
};

export default Buttons;
