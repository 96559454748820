import React, {
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import { debounce, isEmpty } from 'lodash';
import {
  Box,
  Grid,
  IconButton,
  Stack,
  Tooltip
} from '@mui/material';
import AddRoadIcon from '@mui/icons-material/AddRoad';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

import AddOrEditRuleModal from './add-or-edit-rule-modal';
import Button from '../../components/button';
import DeleteRuleModal from './delete-rule-modal';
import SearchBar from '../../components/search';
import SimpleTable from '../../components/table';
import Toast from '../../components/toast';

import {
  GetRules,
  SaveRule,
  SetRulesState
} from '../../reducers/rules';

import { pageChangeRange, formatAmount } from '../../constants';

const Rules = () => {
  const dispatch = useDispatch();
  const {
    rulesData,
    total,
    err,
    loading,
    message,
    success
  } = useSelector((state) => state.rules);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [addRule, setAddRule] = useState(false);
  const [editRule, setEditRule] = useState(false);
  const [errorOnTitleInput, setErrorOnTitleInput] = useState({});
  const [errorOnAsinInput, setErrorOnAsinInput] = useState({});
  const [errorOnCompetitorAsinInput, setErrorOnCompetitorAsinInput] = useState({});
  const [errorOnPriceInput, setErrorOnPriceInput] = useState({});
  const [errorOnLowestPriceInput, setErrorOnLowestPriceInput] = useState({});
  const [errorOnTimeInput, setErrorOnTimeInput] = useState({});
  const [ruleValues, setRuleValues] = useState({});
  const [buttonDisable, setButtonDisable] = useState(false);
  const [documentId, setDocumentId] = useState('');
  const [deleteRuleModal, setDeleteRuleModal] = useState(false);

  const columns = [
    {
      id: 'shortName',
      label: 'Short Name',
      minWidth: 200,
      align: 'left'
    },
    {
      id: 'asin',
      label: 'Asin',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'competitorAsin',
      label: 'Competitor Asin',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'priceDiscount',
      label: 'Price Discount ($)',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'lowestPrice',
      label: 'Lowest Price ($)',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'checkAfter',
      label: 'Check After (Hours)',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 100,
      align: 'left',
      cellStyle: {
        position: 'sticky',
        right: 0,
        background: 'white'
      },
      headerStyle: {
        position: 'sticky',
        right: 0,
        background: 'white'
      }
    }
  ];

  function createData(
    shortName,
    asin,
    competitorAsin,
    priceDiscount,
    lowestPrice,
    checkAfter,
    actions
  ) {
    return {
      shortName,
      asin,
      competitorAsin,
      priceDiscount,
      lowestPrice,
      checkAfter,
      actions
    };
  }

  const handleEditRule = (rule) => {
    if (!isEmpty(rule)) {
      setRuleValues(rule);
      setEditRule(true);
    }
  };

  const handleDeleteRule = (id) => {
    setDocumentId(id);
    setDeleteRuleModal(true);
  };

  const createRulesData = () => {
    const rowList = [];
    for (let i = 0; i < rulesData.length; i += 1) {
      const {
        shortName,
        asin,
        competitorAsin,
        priceDiscount,
        lowestPrice,
        checkAfter
      } = rulesData[i];

      rowList.push(createData(
        shortName || 'N/A',
        asin
          ? (
            <a
              style={{ cursor: 'pointer' }}
              href={`https://www.amazon.com/dp/${asin}`}
              target="_blank"
              rel="noreferrer"
            >
              {asin}
            </a>
          ) : 'N/A',
        competitorAsin ? (
          <a
            style={{ cursor: 'pointer' }}
            href={`https://www.amazon.com/dp/${competitorAsin}`}
            target="_blank"
            rel="noreferrer"
          >
            {competitorAsin}
          </a>
        ) : 'N/A',
        priceDiscount ? formatAmount(priceDiscount) : 'N/A',
        lowestPrice ? formatAmount(lowestPrice) : 'N/A',
        checkAfter || 'N/A',
        <Box>
          <IconButton onClick={() => handleEditRule(rulesData[i])}>
            <Tooltip
              title="Edit Rule"
              placement="top"
            >
              <InfoIcon />
            </Tooltip>
          </IconButton>
          <IconButton onClick={() => handleDeleteRule(rulesData[i]._id)}>
            <Tooltip
              title="Delete Rule"
              placement="top"
            >
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Box>
      ));
    }
    return rowList;
  };

  const pageChange = (page, limit) => {
    setPageLimit(limit);
    setPageNumber(page);
  };

  const handleCloseAddEditRuleModal = () => {
    setErrorOnTitleInput({});
    setErrorOnAsinInput({});
    setErrorOnCompetitorAsinInput({});
    setErrorOnPriceInput({});
    setErrorOnLowestPriceInput({});
    setRuleValues({});
    setButtonDisable(false);
    setEditRule(false);
    setAddRule(false);
  };

  const handleSaveRules = () => {
    if (!isEmpty(ruleValues)
      && ruleValues.shortName
        && ruleValues.asin
          && ruleValues.competitorAsin
            && ruleValues.priceDiscount
              && ruleValues.lowestPrice
                && ruleValues.checkAfter) {
      dispatch(SaveRule({ ruleValues }));
      dispatch(SetRulesState({ field: 'err', value: '' }));
      setButtonDisable(false);
      setPageNumber(1);
      setErrorOnTitleInput({});
      setErrorOnAsinInput({});
      setErrorOnCompetitorAsinInput({});
      setErrorOnPriceInput({});
      setErrorOnLowestPriceInput({});
      setRuleValues({});
      setButtonDisable(false);
      setAddRule(false);
      setEditRule(false);
    } else {
      setMessage('Enter the required fields');
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetRulesState({ field: 'err', value: 'Enter the required fields' }));
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleSearch = debounce((value) => {
    setPageNumber(1);
    setSearchKeyword(value);
  }, 500);

  const handleRefreshButton = () => {
    dispatch(GetRules({
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit
    }));
  };

  useEffect(() => {
    if (rulesData?.length) {
      const result = createRulesData();
      setRowData(result);
    } else {
      setRowData([]);
    }
  }, [rulesData, loading]);

  useEffect(() => {
    dispatch(GetRules({
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit
    }));
  }, [pageNumber, pageLimit, searchKeyword]);

  useEffect(() => {
    if (err) {
      setMessage(err);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetRulesState({ field: 'err', value: '' }));
    }
  }, [err]);

  useEffect(() => {
    if (success && !err && message) {
      dispatch(GetRules({
        searchKeyword,
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit
      }));
      setMessage(message);
      setSeverity('success');
      setToastOpen(true);
      dispatch(SetRulesState({ field: 'success', value: false }));
    }
  }, [success, err]);

  return (
    <>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <h2>Rules</h2>
      </Box>
      <Box className="custom-header" sx={{ backgroundColor: '#fff', p: 3, borderRadius: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <SearchBar placeholder="Asin" onChange={(event) => handleSearch(event.target.value)} />
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button
              text="Add Rule"
              onClick={() => setAddRule(true)}
              varient="outlined"
              icon={<AddRoadIcon />}
            />
          </Stack>
        </Stack>
      </Box>
      <Grid conatiner="true">
        <Grid item md={12}>
          <SimpleTable
            rows={rowData}
            loading={loading}
            totalRows={total}
            columns={columns}
            className="table-height"
            selectLabel="page"
            onChange={pageChange}
            pageLimit={pageLimit}
            pageNumber={pageNumber}
            data={pageChangeRange}
            onRefresh={handleRefreshButton}
          />
        </Grid>
      </Grid>
      <AddOrEditRuleModal
        addRule={addRule}
        editRule={editRule}
        ruleValues={ruleValues}
        setRuleValues={setRuleValues}
        buttonDisable={buttonDisable}
        setButtonDisable={setButtonDisable}
        errorOnTitleInput={errorOnTitleInput}
        setErrorOnTitleInput={setErrorOnTitleInput}
        errorOnAsinInput={errorOnAsinInput}
        setErrorOnAsinInput={setErrorOnAsinInput}
        errorOnCompetitorAsinInput={errorOnCompetitorAsinInput}
        setErrorOnCompetitorAsinInput={setErrorOnCompetitorAsinInput}
        errorOnPriceInput={errorOnPriceInput}
        setErrorOnPriceInput={setErrorOnPriceInput}
        errorOnLowestPriceInput={errorOnLowestPriceInput}
        setErrorOnLowestPriceInput={setErrorOnLowestPriceInput}
        errorOnTimeInput={errorOnTimeInput}
        setErrorOnTimeInput={setErrorOnTimeInput}
        handleCloseAddEditRuleModal={handleCloseAddEditRuleModal}
        handleSaveRules={handleSaveRules}
      />
      <DeleteRuleModal
        deleteRuleModal={deleteRuleModal}
        setDeleteRuleModal={setDeleteRuleModal}
        documentId={documentId}
      />
    </>
  );
};

export default Rules;
