import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Link } from '@mui/material';

import LabelInput from '../../components/input/label-input';
import Button from '../../components/button';
import Toast from '../../components/toast';

import AuthWrapper from './style';

import { signIn, SetAuthState } from '../../reducers/auth';

import { handleMakeRouteArray } from '../../constants';

function SignIn() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const {
    err,
    loading,
    token,
    user
  } = useSelector((state) => state.auth);

  const emailValidation = (tempEmail) => {
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regExp.test(tempEmail);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      dispatch(SetAuthState({ field: 'err', value: 'Email or Password is required.' }));
    } else if (!emailValidation(email)) {
      dispatch(SetAuthState({ field: 'err', value: 'Please Enter a valid email address' }));
    } else {
      dispatch(signIn({ email, password }));
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  useEffect(() => {
    dispatch(SetAuthState({ field: 'err', value: '' }));
  }, []);

  useEffect(() => {
    if (err) {
      setMessage(err);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetAuthState({ field: 'err', value: '' }));
    }
  }, [err]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
      if (user.role === 'normal-user') {
        const notNormalUserPages = handleMakeRouteArray(user.permissions);
        if (notNormalUserPages.includes('/rules')) {
          history.push('/rules');
        } else if (notNormalUserPages.includes('/repricer-logs')) {
          history.push('/repricer-logs');
        } else {
          history.push('/error-logs');
        }
      } else {
        history.push('/rules');
      }
    }
  }, [token]);

  return (
    <>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={5} sm={8} xs={12}>
          <Box textAlign="center">
            <h1 style={{ paddingTop: '16%' }}> Amz Repricer Hen </h1>
          </Box>
          <AuthWrapper>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => handleFormSubmit(e)}
            >
              <LabelInput fullWidth label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
              <LabelInput fullWidth label="Password" value={password} type="password" onChange={(e) => setPassword(e.target.value)} />
              <Box textAlign="right" sx={{ marginBottom: 1 }}>
                <Link
                  color="#175D9B"
                  underline="none"
                  fontSize="16px"
                  className="cursor-pointer"
                  onClick={() => history.push('/auth/forgot-password')}
                >
                  Forgot password?
                </Link>
              </Box>
              <Button
                text="login"
                className="contained w-100"
                type="submit"
                loading={loading}
              />
            </form>
          </AuthWrapper>
        </Grid>
      </Grid>
    </>
  );
}

export default SignIn;
