import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosBaseUrl } from '../config/axios-configuration';

const axios = axiosBaseUrl();

const initialState = {
  jobsData: [],
  total: 0,
  userSelectedAgendaType: '',
  userSelectedJobState: '',
  message: '',
  loading: false,
  err: null,
  success: false
};

export const GetJobs = createAsyncThunk('getJobs', async (data, thunkAPI) => {
  try {
    const {
      jobType,
      jobStatetype,
      searchKeyword,
      sort,
      limit,
      skip
    } = data;
    const response = await axios.get('/jobs/get-jobs', {
      params: {
        jobType,
        jobStatetype,
        searchKeyword,
        sort,
        limit,
        skip
      }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return thunkAPI.rejectWithValue({
        err: err.response.data,
        status: err.response.status
      });
    }
    return thunkAPI.rejectWithValue({
      err: {
        error: 'Network Error'
      }
    });
  }
});

export const RequeueJobs = createAsyncThunk('/requeue-jobs', async (data, thunkAPI) => {
  try {
    const response = await axios.post('/jobs/requeue-job', data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return thunkAPI.rejectWithValue({
        err: error.response.data,
        status: error.response.status
      });
    }
    return thunkAPI.rejectWithValue({
      err: {
        error: 'Network Error'
      }
    });
  }
});

const jobs = createSlice({
  name: 'JobsReducer',
  initialState,
  reducers: {
    SetJobsState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetJobs.pending]: (state) => ({
      ...state,
      err: null,
      loading: true,
      success: false
    }),
    [GetJobs.fulfilled]: (state, action) => ({
      ...state,
      err: null,
      total: action.payload.total,
      jobsData: action.payload.jobsData,
      loading: false,
      message: action.payload.message
    }),
    [GetJobs.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    }),
    [RequeueJobs.pending]: (state) => ({
      ...state,
      err: null,
      loading: true,
      success: false
    }),
    [RequeueJobs.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: null,
      message: action.payload.message,
      success: action.payload.success
    }),
    [RequeueJobs.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    })
  }
});

export const { SetJobsState } = jobs.actions;
export default jobs.reducer;
