import {
  createSlice,
  createAsyncThunk
} from '@reduxjs/toolkit';

import { axiosBaseUrl } from '../config/axios-configuration';

const axios = axiosBaseUrl();

export const DeleteUser = createAsyncThunk(
  'user/deleteUser',
  async (user, thunkAPI) => {
    try {
      const { userId } = user;
      const response = await axios.delete('/users/delete-user', {
        params: {
          userId
        }
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const GetUsers = createAsyncThunk(
  'users/getUsers',
  async (user, thunkAPI) => {
    try {
      const {
        searchKeyWord,
        skip,
        limit
      } = user;
      const response = await axios.get('/users/get-users', {
        params: {
          searchKeyWord,
          skip,
          limit
        }
      });

      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const InviteUser = createAsyncThunk(
  'users/inviteUser',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/users/invite-user', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const UserRegistration = createAsyncThunk(
  'users/userRegistration',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/auth/user-registration', user);
      return response.data;
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

const users = createSlice({
  name: 'users',
  initialState: {
    message: '',
    err: '',
    success: false,
    loading: false,
    users: [],
    total: 0
  },
  reducers: {
    SetUserState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [DeleteUser.pending]: (state) => ({
      ...state,
      loading: true,
      err: null,
      success: false
    }),
    [DeleteUser.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      loading: false,
      err: null,
      success: true
    }),
    [DeleteUser.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error,
      loading: false,
      success: true
    }),
    [GetUsers.pending]: (state) => ({
      ...state,
      loading: true,
      success: false
    }),
    [GetUsers.fulfilled]: (state, action) => ({
      ...state,
      users: action.payload.users,
      total: action.payload.total,
      loading: false
    }),
    [GetUsers.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error,
      loading: false
    }),
    [InviteUser.pending]: (state) => ({
      ...state,
      success: false,
      loading: true
    }),
    [InviteUser.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      success: true,
      loading: false
    }),
    [InviteUser.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error,
      success: true,
      loading: false
    }),
    [UserRegistration.pending]: (state) => ({
      ...state,
      success: false,
      loading: true
    }),
    [UserRegistration.fulfilled]: (state, action) => ({
      ...state,
      message: action.payload.message,
      success: true,
      loading: false
    }),
    [UserRegistration.rejected]: (state, action) => ({
      ...state,
      err: action.payload.err?.error,
      success: true,
      loading: false
    })
  }
});

export const { SetUserState } = users.actions;
export default users.reducer;
