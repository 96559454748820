import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Box,
  ListItemButton,
  Collapse,
  CssBaseline,
  List,
  Divider
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import SidebarWrapper from './style';

import { LogOut } from '../reducers/auth';

const drawerWidth = 260;
const side = [
  {
    title: 'Rules',
    icon: <DesignServicesIcon />,
    route: '/rules',
    collapse: [],
    key: 'RULES'
  },
  {
    title: 'Repricer Logs',
    icon: <PriceChangeIcon />,
    route: '/repricer-logs',
    collapse: [],
    key: 'REPRICER_LOGS'
  },
  {
    title: 'Error Logs',
    icon: <ReportGmailerrorredIcon />,
    route: '/error-logs',
    collapse: [],
    key: 'ERROR_LOGS'
  },
  {
    title: 'Background Tasks',
    icon: <SplitscreenIcon />,
    route: '/background-tasks',
    collapse: [],
    key: 'BACKGROUND_TASKS'
  },
  {
    title: 'Users',
    icon: <PeopleAltIcon />,
    route: '/users',
    collapse: [],
    key: 'USERS'
  },
  {
    title: 'Log out',
    icon: <LogoutOutlinedIcon />,
    route: '/auth/signin',
    key: 'LOG_OUT',
    collapse: []
  }
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme)
    })
  })
);

const Layout = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [collape, setCollpase] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState('');

  const { role = '', permissions } = useSelector((state) => state.auth?.user || {});

  const handleDrawerClose = () => {
    setOpen(!open);
  };
  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };
  const handleCollapse = () => {
    setCollpase(!collape);
  };
  const style = {
    pt: 2,
    pb: 0,
    pl: 3
  };

  const elsePart = (index, text) => (
    <List key={`list-${index}`} component="div" disablePadding>
      <ListItemButton sx={{ pl: 4 }} button="true" key={`list-item-${index}`}
        selected={selectedIndex === text.key || window.location.pathname === text.route}
        onClick={
          text.key === 'SPONSORED_PRODUCTS'
            ? handleCollapse
            : (event) => {
              handleListItemClick(event, index);
              history.push(text.route);
            }
        }
      >
        <ListItemIcon>
          {text.icon}
        </ListItemIcon>
        <ListItemText primary={text.title} />
      </ListItemButton>
    </List>
  );

  const secondElsePart = (index, text) => (
    <div key={`list-item-${index}`}>
      <ListItemButton
        button="true"
        onClick={
          text.key === 'SPONSORED_PRODUCTS' ? handleCollapse
            : (event) => {
              if (text.key === 'LOG_OUT') {
                dispatch(LogOut());
              }
              handleListItemClick(event, text.key);
              history.push(text.route);
            }
        }
        selected={selectedIndex === index || window.location.pathname === text.route}
      >
        <ListItemIcon>
          {text.icon}
        </ListItemIcon>
        <ListItemText primary={text.title} />
        {text.key === 'SPONSORED_PRODUCTS' ? collape ? <ExpandLess sx={{ color: '#62A6E2' }} /> : <ExpandMore sx={{ color: '#62A6E2' }} /> : ''}
      </ListItemButton>
      <Collapse in={text.key === 'SPONSORED_PRODUCTS' ? collape : false} timeout="auto" unmountOnExit>
        {text?.collapse?.map((text, index) => (
          elsePart(index, text)
        ))}
      </Collapse>
    </div>
  );

  return (
    <SidebarWrapper>
      <Box>
        <CssBaseline />
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <MenuIcon sx={{ color: '#175D9B' }} />
            </IconButton>
            {open ? <h1 style={{ fontSize: '19px', margin: '0' }}> Amz Repricer Hen </h1> : ''}
          </DrawerHeader>
          <Divider />
          <List>
            {side.map((text, index) => (
              role === 'normal-user' ? !permissions.includes(text.key) ? '' : secondElsePart(index, text) : secondElsePart(index, text)
            ))}
          </List>
        </Drawer>
        <Box component="main" sx={open === false ? { ...style, ml: 9.12 } : { ...style, ml: 30 }}>
          {children}
        </Box>
      </Box>
    </SidebarWrapper>
  );
};

export default Layout;
