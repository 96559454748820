import logger from 'redux-logger';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import auth from './auth';
import jobs from './jobs';
import repricer from './repricer';
import rules from './rules';
import users from './users';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

const reducers = combineReducers({
  auth,
  jobs,
  repricer,
  rules,
  users
});

const rootReducer = (state, action) => {
  if (action.type === 'authReducer/LogOut') {
    state = undefined;
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default configureStore({
  reducer: persistedReducer,
  middleware: [thunk, logger],
  devTools: true
});
