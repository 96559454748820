import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RegisterUser from '../pages/users/register-user';
import PageNotFound from '../pages/pageNotFound';

import { setAuthToken } from '../config/axios-configuration';

const NonAuthRoute = () => {
  const { token } = useSelector((state) => state.auth);
  setAuthToken(token);

  return (
    <Switch>
      <Route path="/non-auth/register-user" component={RegisterUser} />
      <Route path="/not-found" component={PageNotFound} />
      <Redirect from="*" to="/not-found" />
    </Switch>
  );
};

export default NonAuthRoute;
