import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useLocation } from 'react-router-dom';

import DashboardLayout from '../layout';
import PrivateRoute from './PrivateRoute';
import BackgroundTasks from '../pages/background-tasks';
import ErrorLogs from '../pages/error-logs';
import RepricerLogs from '../pages/repricer-logs';
import Rules from '../pages/rules';
import Users from '../pages/users';
import { handleMakeRouteArray } from '../constants';
import { setAuthToken } from '../config/axios-configuration';

const AppRoute = () => {
  const history = useHistory();
  const location = useLocation();
  const { token, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!token) {
      history.push('/auth/signin');
    } else {
      setAuthToken(token);
      if (location.pathname === '/') {
        if (user.role === 'normal-user') {
          const notNormalUserPages = handleMakeRouteArray(user.permissions);
          if (notNormalUserPages.includes('/rules')) {
            history.push('/rules');
          } else if (notNormalUserPages.includes('/repricer-logs')) {
            history.push('/repricer-logs');
          } else {
            history.push('/error-logs');
          }
        } else {
          history.push('/rules');
        }
      }
    }
  }, [token]);

  return (
    <Switch>
      <PrivateRoute
        path="/background-tasks"
        component={BackgroundTasks}
        withLayout={DashboardLayout}
      />
      <PrivateRoute
        path="/error-logs"
        component={ErrorLogs}
        withLayout={DashboardLayout}
      />
      <PrivateRoute
        path="/repricer-logs"
        component={RepricerLogs}
        withLayout={DashboardLayout}
      />
      <PrivateRoute
        path="/rules"
        component={Rules}
        withLayout={DashboardLayout}
      />
      <PrivateRoute
        path="/users"
        component={Users}
        withLayout={DashboardLayout}
      />
    </Switch>
  );
};

export default AppRoute;
