export const LOCAL_API_URL = 'http://localhost:4000/api/v1';
export const PRODUCTION_API_URL = 'https://app.bspricer.com/api/v1';

export const pageChangeRange = [
  {
    value: 15,
    name: 15
  },
  {
    value: 30,
    name: 30
  },
  {
    value: 50,
    name: 50
  },
  {
    value: 100,
    name: 100
  }
];

export const isValidAsin = (asin) => {
  const isAsin = asin && asin.match(/(B0[\d\w]{8})|[\d]{10}/);
  const isIsbn10 = asin && asin.match(/[\d]{9}([\d]|X)/);
  return !!isAsin || !!isIsbn10;
};

export const formatAmount = (amount) => {
  let newAmount = parseFloat(parseFloat(amount || 0).toFixed(2));
  newAmount = newAmount.toLocaleString(undefined, { minimumFractionDigits: 2 });
  return newAmount;
};

export const handleMakeRouteArray = (permissions) => {
  const finalArray = [];
  for (let i = 0; i < permissions?.length; i += 1) {
    let value = permissions[i].toLowerCase().replace('_', '-');
    value = `/${value}`;
    finalArray.push(value);
  }
  return finalArray;
};

export const handleMakePermissionArray = (permissions) => {
  const finalArray = [];
  for (let i = 0; i < permissions?.length; i += 1) {
    const value = permissions[i].toLowerCase().replace('_', '');
    finalArray.push(value);
  }
  return finalArray.join(', ');
};
