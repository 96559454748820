import React, {
  useState,
  useEffect
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import momentTz from 'moment-timezone';
import { debounce } from 'lodash';
import {
  Box,
  Grid,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import DoneIcon from '@mui/icons-material/Done';

import Button from '../../components/button';
import SearchBar from '../../components/search';
import SimpleTable from '../../components/table';
import Toast from '../../components/toast';

import {
  DownloadRepricerLogs,
  GetRepricerLogs,
  SetRepricerState
} from '../../reducers/repricer';

import { formatAmount, pageChangeRange } from '../../constants';

const RepricerLogs = () => {
  const dispatch = useDispatch();
  const {
    logsData,
    total,
    err,
    loading,
    message,
    success
  } = useSelector((state) => state.repricer);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const columns = [
    {
      id: 'shortName',
      label: 'Short Name',
      minWidth: 200,
      align: 'left'
    },
    {
      id: 'asin',
      label: 'Asin',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'competitorAsin',
      label: 'Competitor Asin',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'priceDiscount',
      label: 'Price Discount',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'lowestPrice',
      label: 'Lowest Price',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'oldPrice',
      label: 'Old Price',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'competitorPrice',
      label: 'Competitor Price',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'newPrice',
      label: 'New Price',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'exported',
      label: 'Exported',
      minWidth: 100,
      align: 'left'
    },
    {
      id: 'priceChangedAt',
      label: 'Price Changed At',
      minWidth: 100,
      align: 'left'
    }
  ];

  function createData(
    shortName,
    asin,
    competitorAsin,
    priceDiscount,
    lowestPrice,
    oldPrice,
    competitorPrice,
    newPrice,
    exported,
    priceChangedAt
  ) {
    return {
      shortName,
      asin,
      competitorAsin,
      priceDiscount,
      lowestPrice,
      oldPrice,
      competitorPrice,
      newPrice,
      exported,
      priceChangedAt
    };
  }

  const pageChange = (page, limit) => {
    setPageLimit(limit);
    setPageNumber(page);
  };

  const createLogsData = () => {
    const rowList = [];
    for (let i = 0; i < logsData.length; i += 1) {
      const {
        shortName,
        asin,
        competitorAsin,
        priceDiscount,
        lowestPrice,
        oldPrice,
        competitorPrice,
        newPrice,
        exported,
        priceChangedAt
      } = logsData[i];
      rowList.push(createData(
        shortName || 'N/A',
        asin || 'N/A',
        competitorAsin || 'N/A',
        priceDiscount ? formatAmount(priceDiscount) : 'N/A',
        lowestPrice ? formatAmount(lowestPrice) : 'N/A',
        oldPrice ? formatAmount(oldPrice) : 'N/A',
        competitorPrice ? formatAmount(competitorPrice) : 'N/A',
        newPrice ? formatAmount(newPrice) : 'N/A',
        exported ? <Box><DoneIcon sx={{ color: 'green' }} /></Box> : <Box><CloseIcon sx={{ color: 'red' }} /></Box>,
        priceChangedAt ? momentTz(priceChangedAt).tz('America/Los_Angeles').format('lll') : 'N/A'
      ));
    }
    return rowList;
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };
  const handleSearch = debounce((value) => {
    setPageNumber(1);
    setSearchKeyword(value);
  }, 500);

  const handleRefreshButton = () => {
    const setObj = {
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit
    };
    dispatch(GetRepricerLogs({ ...setObj }));
  };

  useEffect(() => {
    if (logsData?.length) {
      const result = createLogsData();
      setRowData(result);
    } else {
      setRowData([]);
    }
  }, [logsData, loading]);

  useEffect(() => {
    const setObj = {
      searchKeyword,
      limit: pageLimit,
      skip: (pageNumber - 1) * pageLimit
    };
    dispatch(GetRepricerLogs({ ...setObj }));
  }, [pageNumber, pageLimit, searchKeyword]);

  useEffect(() => {
    if (err) {
      setMessage(err);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetRepricerState({ field: 'err', value: '' }));
    }
  }, [err]);

  useEffect(() => {
    if (success && !err && message) {
      setMessage(message);
      setSeverity('success');
      setToastOpen(true);
      dispatch(SetRepricerState({ field: 'success', value: false }));
    }
  }, [success, err]);

  return (
    <>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }} mr={1}>
        <h2>Repricer Logs</h2>
      </Box>
      <Box className="custom-header" sx={{ backgroundColor: '#fff', p: 3, borderRadius: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <SearchBar placeholder="Search Asin" onChange={(event) => handleSearch(event.target.value)} />
          <Stack direction="row" spacing={2}>
            <Button
              text="Repricer Logs"
              onClick={() => dispatch(DownloadRepricerLogs())}
              varient="outlined"
              icon={<DownloadIcon />}
            />
          </Stack>
        </Stack>
      </Box>
      <Grid conatiner="true">
        <Grid item md={12}>
          <SimpleTable
            rows={rowData}
            loading={loading}
            totalRows={total}
            columns={columns}
            className="table-height"
            selectLabel="page"
            onChange={pageChange}
            pageLimit={pageLimit}
            pageNumber={pageNumber}
            data={pageChangeRange}
            onRefresh={handleRefreshButton}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RepricerLogs;
