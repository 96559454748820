import Styled from 'styled-components';

const InputWrapper = Styled.div`
  fieldset{
    border: 1.5px solid #EDEDED;
  }
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    height: 35px
  }
`;

export default InputWrapper;
