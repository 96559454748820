import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import SignIn from '../pages/auth/sign-in';
import PageNotFound from '../pages/pageNotFound';

import { setAuthToken } from '../config/axios-configuration';

const AuthRoute = () => {
  const { token } = useSelector((state) => state.auth);
  setAuthToken(token);

  return (
    <Switch>
      <PublicRoute path="/auth/signin" component={SignIn} />
      <Route path="/not-found" component={PageNotFound} />
      <Redirect from="*" to="/not-found" />
    </Switch>
  );
};

export default AuthRoute;
