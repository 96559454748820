import * as React from 'react';
import TextField from '@mui/material/TextField';

import LabelInputWrapper from './style';

export default function BasicTextFields(props) {
  const {
    label,
    name,
    value,
    onChange,
    type,
    loading,
    width,
    fullWidth = false,
    helperText,
    error,
    placeholder,
    className,
    disabled
  } = props;
  return (
    <LabelInputWrapper className={className}>
      <label>{label}</label>
      <TextField
        fullWidth={fullWidth}
        size="small"
        placeholder={placeholder}
        id={`outlined-basic-${label}`}
        variant="outlined"
        name={name}
        helperText={helperText}
        error={error}
        value={value}
        onChange={onChange}
        type={type || 'text'}
        disabled={loading || disabled}
        autoComplete="off"
        sx={{ width }}
      />
    </LabelInputWrapper>
  );
}
