import Styled from 'styled-components';

const SidebarWrapper = Styled.div`
  .MuiListItemButton-root{
     font-family: ${({ theme }) => theme['font-family']};
     margin-bottom:3px;
    &.Mui-selected{
           background-color: ${({ theme }) => theme['primary-color']};
        .MuiListItemIcon-root{
             color: ${({ theme }) => theme['white-color']};
    }
    .MuiListItemText-root{
        color: ${({ theme }) => theme['white-color']};
      }
         &:hover{
       background-color: ${({ theme }) => theme['primary-color']};
    }
    }
       &:hover {
            background-color: ${({ theme }) => theme['primary-color']};
           .MuiListItemIcon-root,.MuiListItemText-root,.MuiSvgIcon-root{
             color: ${({ theme }) => theme['white-color']};
    }
    }
      .MuiListItemIcon-root,.MuiListItemText-root{
        color: ${({ theme }) => theme['secondary-color']};
        span{
        font-size:1rem;
         font-family: ${({ theme }) => theme['font-family']};
         font-weight:500
        }
      }
}
`;
export default SidebarWrapper;
