import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Theme from './assets/themes';
import AppRoute from './routes/AppRoute';
import AuthRoute from './routes/AuthRoute';
import NonAuthRoute from './routes/NonAuthRoute';

import './assets/css/style.css';

const App = () => (
  <ThemeProvider theme={{ ...Theme }}>
    <BrowserRouter>
      <Switch>
        <AuthRoute path="/auth" />
        <NonAuthRoute path="/non-auth" />
        <AppRoute path="/" />
      </Switch>
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
