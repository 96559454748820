import React, {
  useEffect,
  useState
} from 'react';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import moment from 'moment';
import { debounce, floor, extend } from 'lodash';
import {
  Box,
  IconButton,
  Grid,
  Stack,
  Tooltip
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import Toast from '../../components/toast';
import SearchBar from '../../components/search';
import Select from '../../components/select';
import SimpleTable from '../../components/table';

import { GetJobs, RequeueJobs, SetJobsState } from '../../reducers/jobs';

import { pageChangeRange } from '../../constants';

const jobsTypeData = [
  {
    name: 'Pool1 Jobs',
    value: 'Pool1Jobs'
  },
  {
    name: 'Pool2 Jobs',
    value: 'Pool2Jobs'
  },
  {
    name: 'Pool3 Jobs',
    value: 'Pool3Jobs'
  }
];

const jobStateData = [
  {
    name: 'All',
    value: 'All'
  },
  {
    name: 'In Progress',
    value: '_IN_PROGRESS_'
  },
  {
    name: 'Completed',
    value: '_COMPLETED_'
  },
  {
    name: 'Failed',
    value: '_FAILED_'
  },
  {
    name: 'Started',
    value: '_STARTED_'
  },
  {
    name: 'Empty',
    value: '_EMPTY_'
  }
];

const BackgroundTasks = () => {
  const dispatch = useDispatch();

  const {
    jobsData,
    total,
    err,
    loading,
    message,
    success,
    userSelectedAgendaType,
    userSelectedJobState
  } = useSelector((state) => state.jobs);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(100);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowData, setRowData] = useState([]);
  const [toastMessage, setToastMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [toastOpen, setToastOpen] = useState(false);

  const columns = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 250,
      align: 'left',
      sortingId: 'name'
    },
    {
      id: 'state',
      label: 'State',
      minWidth: 170,
      align: 'left',
      sortingId: 'state'
    },
    {
      id: 'progress',
      label: 'Progress',
      minWidth: 100,
      align: 'left',
      sortingId: 'progress'
    },
    {
      id: 'nextRunAt',
      label: 'Next Run At',
      minWidth: 170,
      align: 'left',
      sortingId: 'nextRunAt'
    },
    {
      id: 'lockedAt',
      label: 'Locked At',
      minWidth: 190,
      align: 'left',
      sortingId: 'lockedAt'
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 170,
      align: 'left',
      cellStyle: {
        position: 'sticky',
        right: 0,
        background: 'white'
      },
      headerStyle: {
        position: 'sticky',
        right: 0,
        background: 'white'
      }
    }
  ];

  function createData(
    name,
    state,
    progress,
    nextRunAt,
    lockedAt,
    actions
  ) {
    return {
      name,
      state,
      progress,
      nextRunAt,
      lockedAt,
      actions
    };
  }

  const handleNextRunAt = (nextRunAt) => {
    const time = moment(nextRunAt).format('llll');
    const timeDuration = moment.duration(moment(nextRunAt).diff(moment()));
    const hours = timeDuration.hours();
    const minutes = timeDuration.minutes();
    const days = floor(timeDuration.asDays());
    if (minutes >= 0) {
      if (days >= 1) {
        return `${time} (${days}D ${hours}H ${minutes}M))`;
      }
      return `${time} (${hours}H ${minutes}M)`;
    }
    return `${time}`;
  };

  const handleRequeueJobModal = (jobId) => {
    dispatch(RequeueJobs({ jobId, jobType: userSelectedAgendaType }));
  };

  const createJobsData = () => {
    const rowList = [];
    for (let i = 0; i < jobsData?.length; i += 1) {
      rowList.push(createData(
        jobsData[i].name,
        jobsData[i].state,
        jobsData[i].progress,
        handleNextRunAt(jobsData[i].nextRunAt),
        jobsData[i].lockedAt || 'null',
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Tooltip title="Requeue" placement="top">
            <IconButton onClick={(e) => e && handleRequeueJobModal(jobsData[i]._id)}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ));
    }
    return rowList;
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const handleSearch = debounce((value) => {
    setPageNumber(1);
    setSearchKeyword(value);
  }, 500);

  const handleJobType = (value) => {
    setPageNumber(1);
    dispatch(SetJobsState({ field: 'userSelectedAgendaType', value }));
  };

  const handleJobStateType = (value) => {
    setPageNumber(1);
    dispatch(SetJobsState({ field: 'userSelectedJobState', value }));
  };

  const pageChange = (page, limit) => {
    setPageLimit(limit);
    setPageNumber(page);
  };

  const refreshJobs = () => {
    if (userSelectedAgendaType && userSelectedJobState) {
      const setObj = {
        jobType: userSelectedAgendaType,
        jobStatetype: userSelectedJobState,
        searchKeyword,
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit
      };
      if (orderBy !== '') {
        extend(setObj, { sort: { [orderBy]: order === 'asc' ? 1 : -1 } });
      }
      dispatch(GetJobs({ ...setObj }));
    }
  };

  useEffect(() => {
    if (jobsData?.length) {
      const result = createJobsData();
      setRowData(result);
    } else {
      setRowData([]);
    }
  }, [jobsData, loading]);

  useEffect(() => {
    if (!userSelectedAgendaType) {
      dispatch(SetJobsState({ field: 'userSelectedAgendaType', value: 'Pool1Jobs' }));
    }
    if (!userSelectedJobState) {
      dispatch(SetJobsState({ field: 'userSelectedJobState', value: 'All' }));
    }
  }, []);

  useEffect(() => {
    if (err) {
      setToastMessage(err);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetJobsState({ field: 'err', value: '' }));
    }
  }, [err]);

  useEffect(() => {
    if (userSelectedAgendaType && userSelectedJobState) {
      const setObj = {
        jobType: userSelectedAgendaType,
        jobStatetype: userSelectedJobState,
        searchKeyword,
        limit: pageLimit,
        skip: (pageNumber - 1) * pageLimit
      };
      if (orderBy !== '') {
        extend(setObj, { sort: { [orderBy]: order === 'asc' ? 1 : -1 } });
      }
      dispatch(GetJobs({ ...setObj }));
    }
  }, [
    userSelectedAgendaType,
    userSelectedJobState,
    pageNumber,
    pageLimit,
    order,
    orderBy,
    success,
    searchKeyword
  ]);

  useEffect(() => {
    if (success && !err && message) {
      setToastMessage(message);
      setSeverity('success');
      setToastOpen(true);
      dispatch(SetJobsState({ field: 'success', value: false }));
    }
  }, [success, err]);

  return (
    <>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <h2>Background Tasks</h2>
      </Box>
      <Box className="custom-header" sx={{ backgroundColor: '#fff', p: 3, borderRadius: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <SearchBar placeholder="Search Task Name" onChange={(event) => handleSearch(event.target.value)} />
          <Stack direction="row" spacing={2}>
            <Select
              data={jobsTypeData}
              handleChange={(event) => handleJobType(event.target.value)}
              value={userSelectedAgendaType}
              sx={{ width: 185 }}
            />
            <Select
              data={jobStateData}
              handleChange={(event) => handleJobStateType(event.target.value)}
              value={userSelectedJobState}
              sx={{ width: 185 }}
            />
          </Stack>
        </Stack>
      </Box>
      <Grid conatiner="true">
        <Grid item md={12}>
          <SimpleTable
            rows={rowData}
            loading={loading}
            totalRows={total}
            columns={columns}
            className="table-height"
            selectLabel="page"
            onChange={pageChange}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            pageLimit={pageLimit}
            pageNumber={pageNumber}
            data={pageChangeRange}
            onRefresh={refreshJobs}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default BackgroundTasks;
