import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosBaseUrl } from '../config/axios-configuration';

const axios = axiosBaseUrl();

const initialState = {
  logsData: [],
  errorLogsData: [],
  total: 0,
  loading: false,
  err: null,
  success: false
};

export const DownloadErrorLogs = createAsyncThunk(
  'download-error-logs',
  async (data, { getState }, thunkAPI) => {
    const state = getState().auth;
    try {
      const params = {
        userId: state.user._id
      };
      const getQueryString = (p) => {
        const esc = encodeURIComponent;
        return Object.keys(p)
          .map((k) => `${esc(k)}=${esc(params[k])}`)
          .join('&');
      };

      const url = (process.env.NODE_ENV === 'development')
        ? `http://localhost:4000/api/v1/auth/download-error-logs?${getQueryString(params)}`
        : `https://app.bspricer.com/api/v1/auth/download-error-logs?${getQueryString(params)}`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const DownloadRepricerLogs = createAsyncThunk(
  'download-repricer-logs',
  async (data, { getState }, thunkAPI) => {
    const state = getState().auth;
    try {
      const params = {
        userId: state.user._id
      };
      const getQueryString = (p) => {
        const esc = encodeURIComponent;
        return Object.keys(p)
          .map((k) => `${esc(k)}=${esc(params[k])}`)
          .join('&');
      };

      const url = (process.env.NODE_ENV === 'development')
        ? `http://localhost:4000/api/v1/auth/download-repricer-logs?${getQueryString(params)}`
        : `https://app.bspricer.com/api/v1/auth/download-repricer-logs?${getQueryString(params)}`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 0);
    } catch (err) {
      if (err.response && err.response.data) {
        return thunkAPI.rejectWithValue({
          err: err.response.data,
          status: err.response.status
        });
      }
      return thunkAPI.rejectWithValue({
        err: {
          error: 'Network Error'
        }
      });
    }
  }
);

export const GetErrorLogs = createAsyncThunk('/get-error-logs', async (data, thunkAPI) => {
  try {
    const {
      limit,
      skip
    } = data;
    const response = await axios.get('/repricer/get-error-logs', {
      params: {
        limit,
        skip
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return thunkAPI.rejectWithValue({
        err: error.response.data,
        status: error.response.status
      });
    }
    return thunkAPI.rejectWithValue({
      err: {
        error: 'Network Error'
      }
    });
  }
});

export const GetRepricerLogs = createAsyncThunk('/get-repricer-logs', async (data, thunkAPI) => {
  try {
    const {
      searchKeyword,
      limit,
      skip
    } = data;
    const response = await axios.get('/repricer/get-repricer-logs', {
      params: {
        searchKeyword,
        limit,
        skip
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return thunkAPI.rejectWithValue({
        err: error.response.data,
        status: error.response.status
      });
    }
    return thunkAPI.rejectWithValue({
      err: {
        error: 'Network Error'
      }
    });
  }
});

const repricer = createSlice({
  name: 'repricerReducer',
  initialState,
  reducers: {
    SetRepricerState(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [DownloadErrorLogs.pending]: (state) => ({
      ...state,
      loading: true,
      success: false
    }),
    [DownloadErrorLogs.fulfilled]: (state) => ({
      ...state,
      loading: false,
      err: null,
      success: true
    }),
    [DownloadErrorLogs.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    }),
    [DownloadRepricerLogs.pending]: (state) => ({
      ...state,
      loading: true,
      success: false
    }),
    [DownloadRepricerLogs.fulfilled]: (state) => ({
      ...state,
      loading: false,
      err: null,
      success: true
    }),
    [DownloadRepricerLogs.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    }),
    [GetErrorLogs.pending]: (state) => ({
      ...state,
      loading: true,
      err: null,
      success: false
    }),
    [GetErrorLogs.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: null,
      errorLogsData: action.payload.errorLogsData,
      total: action.payload.total,
      message: action.payload.message
    }),
    [GetErrorLogs.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    }),
    [GetRepricerLogs.pending]: (state) => ({
      ...state,
      loading: true,
      err: null,
      success: false
    }),
    [GetRepricerLogs.fulfilled]: (state, action) => ({
      ...state,
      loading: false,
      err: null,
      logsData: action.payload.logsData,
      total: action.payload.total,
      message: action.payload.message
    }),
    [GetRepricerLogs.rejected]: (state, action) => ({
      ...state,
      loading: false,
      err: action.payload.err?.error,
      success: false
    })
  }
});

export const { SetRepricerState } = repricer.actions;
export default repricer.reducer;
