import Styled from 'styled-components';

const AuthWrapper = Styled.div`
    h1{
      font-family: 'Neuton', serif;
      font-size:36px;
    }
    label{
    color: ${({ theme }) => theme['dove-grey']};
    font-family: 'Poppins', sans-serif;
    }
    .MuiFormControl-root{
      margin-top:5px;
    fieldset{
      border: 1px solid #929292;
    }
  }
  .remember-me{
    label{
        .MuiTypography-root{
          color: ${({ theme }) => theme['outer-space-lite']};
            font-family: 'Poppins', sans-serif;
        }
    }
  }
  .auth-footer{
    span{
      font-size:16px;
         color: ${({ theme }) => theme['dove-grey']};
         margin-right:5px
    }
  }
  `;

export default AuthWrapper;
