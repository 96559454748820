import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { debounce } from 'lodash';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Grid,
  Divider,
  Tooltip,
  IconButton
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import Button from '../../components/button';
import LabelInput from '../../components/input/label-input';
import Loading from '../../components/loading';
import BasicModal from '../../components/modal';
import SearchBar from '../../components/search';
import SimpleTable from '../../components/table';
import Toast from '../../components/toast';

import AdminWrapper from './style';

import {
  SetUserState,
  DeleteUser,
  GetUsers,
  InviteUser
} from '../../reducers/users';
import { handleMakePermissionArray } from '../../constants';

const closeStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: '0px 4px 8px rgba(54, 50, 235, 0.23)',
  width: '50%',
  bgcolor: 'background.paper'
};

const data = [
  {
    value: 15,
    name: 15
  },
  {
    value: 30,
    name: 30
  },
  {
    value: 50,
    name: 50
  },
  {
    value: 100,
    name: 100
  }
];

const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 200
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left'
  },
  {
    id: 'permissions',
    label: 'Permissions',
    minWidth: 170,
    align: 'left'
  },
  {
    id: 'signUpDate',
    label: 'SignUp Date',
    minWidth: 170,
    align: 'left'
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 170,
    align: 'left'
  },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170,
    align: 'center',
    cellStyle: {
      position: 'sticky',
      right: 0,
      background: 'white'
    },
    headerStyle: {
      position: 'sticky',
      right: 0,
      background: 'white'
    }
  }
];

function createData(
  name,
  email,
  permissions,
  signUpDate,
  status,
  actions
) {
  return {
    name,
    email,
    permissions,
    signUpDate,
    status,
    actions
  };
}

const Index = () => {
  const dispatch = useDispatch();
  const {
    err,
    message,
    users,
    total,
    success,
    loading
  } = useSelector((state) => state.users);

  const [closeModal, setCloseModal] = useState(false);
  const [inviteAdmin, setInviteAdmin] = useState(false);
  const [email, setEmail] = useState('');
  const [searchKeyWord, setSearchKeyWord] = useState('');
  const [rowData, setRowData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(15);
  const [adminId, setAdminId] = useState('');
  const [permissionsArray, setPermissionsArray] = useState(['LOG_OUT']);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const emailValidation = (email) => {
    const regExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regExp.test(email);
  };

  const handleSentInvite = (e, email, permissions) => {
    e.preventDefault();
    if (!email) {
      dispatch(SetUserState({ field: 'err', value: 'Email is required.' }));
    } else if (permissions ? permissions.length === 1 : permissionsArray.length === 1) {
      dispatch(SetUserState({ field: 'err', value: 'Permissions is required.' }));
    } else if (!emailValidation(email)) {
      dispatch(SetUserState({ field: 'err', value: 'Please Enter a valid email address' }));
    } else {
      dispatch(InviteUser({ email, permissions: permissions || permissionsArray }));
      setInviteAdmin(false);
      setEmail('');
    }
  };

  const handleDeleteAdmin = (userId) => {
    setCloseModal(true);
    setAdminId(userId);
  };

  const deleteAdmin = (userId) => {
    dispatch(DeleteUser({ userId }));
    setCloseModal(false);
  };

  const createUsersData = () => {
    const rowList = [];
    for (let i = 0; i < users?.length; i += 1) {
      const {
        _id,
        name: userName,
        email: userEmail,
        permissions,
        signUpDate,
        status
      } = users[i];
      rowList.push(createData(
        userName || 'N/A',
        userEmail,
        permissions ? handleMakePermissionArray(permissions) : 'N/A',
        signUpDate ? moment(signUpDate).format('ll') : 'N/A',
        <Tooltip title={status === 'Pending' ? 'Your invite status still in pending' : ''} placement="top">
          <span className={status === 'Pending' ? 'status error' : 'status sucsses'}>{status}</span>
        </Tooltip>,
        <Box display="flex" justifyContent="center">
          <Stack>
            <Tooltip title="Re-Invite" placement="top">
              <span>
                <IconButton onClick={(e) => { handleSentInvite(e, userEmail, permissions); }} disabled={status !== 'Pending'}>
                  <PersonAddIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
          <Stack>
            <Tooltip title="Delete User" placement="top">
              <IconButton onClick={() => handleDeleteAdmin(_id)}>
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        </Box>
      ));
    }
    return rowList;
  };

  const pageChange = (page, limit) => {
    setPageLimit(limit);
    setPageNumber(page);
  };

  const handleCheckBox = (string) => {
    const filtersList = [...permissionsArray];
    const checkFilter = filtersList.findIndex((item) => item === string);
    if (checkFilter !== -1) {
      filtersList.splice(checkFilter, 1);
    } else {
      filtersList.push(string);
    }
    setPermissionsArray(filtersList);
  };

  const handleSearch = debounce((value) => {
    setPageNumber(1);
    setSearchKeyWord(value);
  }, 500);

  const handleRefreshButton = () => {
    dispatch(GetUsers({
      searchKeyWord,
      skip: (pageNumber - 1) * pageLimit,
      limit: pageLimit
    }));
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  useEffect(() => {
    if (users?.length) {
      const result = createUsersData();
      setRowData(result);
    } else {
      setRowData([]);
    }
  }, [users]);

  useEffect(() => {
    dispatch(GetUsers({
      searchKeyWord,
      skip: (pageNumber - 1) * pageLimit,
      limit: pageLimit
    }));
  }, [pageNumber, pageLimit, searchKeyWord]);

  useEffect(() => {
    if (success && !err && message) {
      dispatch(GetUsers({
        searchKeyWord,
        skip: (pageNumber - 1) * pageLimit,
        limit: pageLimit
      }));
      setInviteAdmin(false);
      setMessage(message);
      setSeverity('success');
      setToastOpen(true);
      setEmail('');
      dispatch(SetUserState({ field: 'success', value: false }));
    }
  }, [success]);

  useEffect(() => {
    if (err) {
      setMessage(err);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetUserState({ field: 'err', value: '' }));
    }
  }, [err]);

  return (
    <>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <AdminWrapper>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <h2>Users</h2>
        </Box>
        <Box className="custom-header" sx={{ backgroundColor: '#fff', p: 3, borderRadius: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <SearchBar placeholder ="Name/Email" onChange={(e) => handleSearch(e.target.value)} />
            <Button
              text="Invite User"
              className="contained w-100"
              type="submit"
              onClick={() => setInviteAdmin(true)}
            />
          </Stack>
        </Box>
        <Grid conatiner="true">
          <Grid item md={12}>
            <SimpleTable
              rows={rowData}
              data={data}
              loading={loading}
              totalRows={total}
              onChange={pageChange}
              pageLimit={pageLimit}
              pageNumber={pageNumber}
              columns={columns}
              className="table-height"
              selectLabel="page"
              onRefresh={handleRefreshButton}
            />
          </Grid>
        </Grid>
        <BasicModal modalToggle={closeModal}>
          <Box sx={closeStyle}>
            <Box display="flex" flexDirection="column" justifyContent="center" p={2} alignItems="center">
              <h1>Are you sure?</h1>
              <p className="text-center">
                Do you really want to delete this user? This
                action will not be undone.

              </p>
            </Box>
            <Divider />
            <Box display="flex" justifyContent="center" columnGap={3} p={2} alignItems="center">
              <Button text="No" onClick={() => setCloseModal(false)} varient="outlined" className="outlined" />
              <Button text="Yes" varient="contained" className="modal-contained" onClick={() => deleteAdmin(adminId)} />
            </Box>
          </Box>
        </BasicModal>
        <BasicModal modalToggle={inviteAdmin}>
          <Box sx={closeStyle}>
            <Box sx={{ textAlign: 'center' }}>
              <h4>Invite User</h4>
            </Box>
            <Divider />
            <Box p={2} sx={{ maxWidth: '275px', margin: '0 auto' }}>
              <label>Email*</label>
              <LabelInput
                label=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                loading={loading}
                width="100%"
              />
              <div>
                <label>Permissions*</label>
              </div>
              <Box sx={{ paddingLeft: '12px' }}>
                <Box>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={() => handleCheckBox('RULES')}
                      />
                    )}
                    label="Rules"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={() => handleCheckBox('REPRICER_LOGS')}
                      />
                    )}
                    label="Repricer Logs"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        onChange={() => handleCheckBox('ERROR_LOGS')}
                      />
                    )}
                    label="Error Logs"
                  />
                </Box>
              </Box>
              {loading ? <Loading paddingTop="0%" /> : ''}
            </Box>
            <Divider />
            <Box display="flex" justifyContent="center" columnGap={3} p={2} alignItems="center">
              <Button text="cancel" onClick={() => setInviteAdmin(false)} varient="outlined" className="outlined" />
              <Button text="Send" varient="contained" className="modal-contained" onClick={(e) => handleSentInvite(e, email)} disabled={loading} />
            </Box>
          </Box>
        </BasicModal>
      </AdminWrapper>
    </>
  );
};

export default Index;
