import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosBaseUrl } from '../config/axios-configuration';

const axios = axiosBaseUrl();

const initialState = {
  token: null,
  user: {},
  loading: false,
  err: null,
  success: false
};

export const signIn = createAsyncThunk('/signin', async (data, thunkAPI) => {
  try {
    const response = await axios.post('/auth/signin', data);
    if (response.status === 200) {
      return {
        token: response.data.token,
        user: response.data.user
      };
    }
  } catch (error) {
    if (error.response && error.response.data) {
      return thunkAPI.rejectWithValue({
        err: error.response.data,
        status: error.response.status
      });
    }
    return thunkAPI.rejectWithValue({
      err: {
        error: 'Network Error'
      }
    });
  }
});

const auth = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    SetAuthState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    LogOut: () => ({
      token: null,
      user: {},
      loading: false,
      err: null,
      success: false
    })
  },
  extraReducers: {
    [signIn.pending]: (state) => ({
      ...state,
      token: null,
      user: {},
      loading: true,
      err: null,
      success: false
    }),
    [signIn.fulfilled]: (state, action) => ({
      ...state,
      token: action.payload.token.token,
      user: action.payload.user,
      loading: false,
      err: null,
      success: true
    }),
    [signIn.rejected]: (state, action) => ({
      ...state,
      token: null,
      user: {},
      loading: false,
      err: action.payload,
      success: false
    })
  }
});

export const { SetAuthState, LogOut } = auth.actions;
export default auth.reducer;
