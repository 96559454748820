import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useJwt } from 'react-jwt';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import LabelInput from '../../components/input/label-input';
import Button from '../../components/button';
import Toast from '../../components/toast';

import AuthWrapper from '../auth/style';

import {
  SetUserState,
  UserRegistration
} from '../../reducers/users';

const RegisterUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    err,
    message,
    success
  } = useSelector((state) => state.users);

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setMessage] = useState('');
  const [severity, setSeverity] = useState('');

  const queryParams = new URLSearchParams(useLocation().search);
  const queryToken = queryParams.get('token');
  const { decodedToken } = useJwt(queryToken);
  const { exp, email } = decodedToken || {};

  if (moment.unix(exp).isBefore(moment())) {
    history.push('/auth/signin');
  }

  if (!queryToken) {
    history.push('/auth/signin');
  }

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const passwordValidation = (password) => {
    const regEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/;
    if (!regEx.test(password)) {
      return false;
    }
    return true;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!password || !confirmPassword || !name) {
      dispatch(SetUserState({ field: 'err', value: 'Fill the required fields' }));
    } else if (password !== confirmPassword) {
      dispatch(SetUserState({ field: 'err', value: 'Password and Confirm Password should be same.' }));
    } else if (!passwordValidation(password)) {
      dispatch(SetUserState({ field: 'err', value: 'Password must contain 1 upper case letter, 1 lowercase letter, and minimum 6 characters long.' }));
    } else {
      dispatch(UserRegistration({
        name,
        email,
        password,
        role: 'normal-user'
      }));
      dispatch(SetUserState({ field: 'err', value: '' }));
    }
  };

  useEffect(() => {
    dispatch(SetUserState({ field: 'err', value: '' }));
    dispatch(SetUserState({ field: 'message', value: '' }));
  }, []);

  useEffect(() => {
    if (success && !err) {
      setMessage(message);
      setSeverity('success');
      setToastOpen(true);
    }
  }, [success]);

  useEffect(() => {
    if (err) {
      setMessage(err);
      setSeverity('error');
      setToastOpen(true);
      dispatch(SetUserState({ field: 'err', value: '' }));
    }
  }, [err]);

  return (
    <>
      <Toast
        message={toastMessage}
        severity={severity}
        toastOpen={toastOpen}
        handleToastClose={handleToastClose}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Grid item lg={4} md={5} sm={8} xs={12}>
          <Box textAlign="center">
            <h1 style={{ paddingTop: '16%' }}> Amz Repricer Hen </h1>
          </Box>
          <AuthWrapper>
            <Box textAlign="center">
              <h1>Register</h1>
            </Box>
            <form
              noValidate
              autoComplete="off"
              onSubmit={(e) => handleFormSubmit(e)}
            >
              <LabelInput fullWidth label="Name*" value={name} onChange={(e) => setName(e.target.value)} />
              <LabelInput fullWidth label="Email" value={email} disabled />
              <LabelInput fullWidth label="Password*" type="password" onChange={(e) => setPassword(e.target.value)} />
              <LabelInput fullWidth label="Confirm Password*" type="password" onChange={(e) => setConfirmPassword(e.target.value)} />
              {!success
                ? (
                  <Button
                    text="Accept Invitation"
                    className="contained w-100"
                    type="submit"
                  />
                ) : ''}
            </form>
          </AuthWrapper>
          {success && !err
            ? (
              <Button
                text="Go to login"
                className="contained w-100"
                varient="outlined"
                onClick={() => history.push('/auth/signin')}
              />
            ) : ''}
        </Grid>
      </Grid>
    </>
  );
};

export default RegisterUser;
